// League Standings

.page-header {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $white;
  color: $dark-blue;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  @media #{$breaketpoint-mobile} {
    height: 40px;
    font-size: 16px;
  }
  .pageBack {
    width: 60px;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    height: 100%;
    @media #{$breaketpoint-mobile} {
      width: 25px;
    }
    i {
      font-size: 24px;
      @media #{$breaketpoint-mobile} {
        font-size: 18px;
      }
    }
  }
}
.tournament-content {
  padding: 0 20px 20px;
  @media #{$breaketpoint-mobile} {
    padding: 5px 5px 10px;
    background-color: #001a69;
    /*for page scroll */
    width: auto;
    overflow-x: scroll;
  }
}

.tournament-landingPage {
  // background-color: #052754;
  border-top: 1px solid $white;
  .btn-group {
    padding: 20px 0;
    @media #{$breaketpoint-mobile} {
      padding: 5px 0;
      margin: 0 5px;
      font-size: 14px;
    }
  }
  .tournament-content {
    // padding: 0 100px;
    // background-color: $white;
    .tabSection {
      background-color: $white;
      padding: 0px 30px 30px;
      width: unset;
      border-radius: 4px;
      @media #{$breaketpoint-mobile} {
        padding: 0 5px 10px;
      }
    }
    .tabsContent {
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 10px;
      background-color: #dde4ed;
      @media #{$breaketpoint-mobile} {
        padding: 5px;
      }
    }
  }
}

.countryTabList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  @media #{$breaketpoint-mobile} {
    margin-top: 10px;
  }
  li {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    width: 260px;
    @media #{$breaketpoint-mobile} {
      font-size: 11px;
      min-width: 110px;
      width: auto;
    }
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #222222;
      padding: 6px 10px;
      @media #{$breaketpoint-mobile} {
        padding: 3px 5px;
      }
    }
    &.active a {
      border: 1px solid #ccc;
      background-color: #dde4ed;
      border-bottom-color: #ececec;
      border-radius: 3px 3px;
      position: relative;
      bottom: -2px;
    }
    .country-flag {
      margin: 6px;
      @media #{$breaketpoint-mobile} {
        margin: 2px;
      }
    }
  }
}

.countryTabContent {
  ul {
    li {
      width: 286px;
      height: 32px;
      // background-color: #bed2ec;
      border-radius: 4px;
      padding: 6px 10px;
      margin: 3px 0;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      color: #222222;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      @media #{$breaketpoint-mobile} {
        width: 43%;
        height: 20px;
        font-size: 11px;
      }
      // @media #{$breaketpoint-minimobile}
      // {
      //   width: 43%;
      // }
    }
  }
}

.monthCarousel {
  text-align: center;
  @media #{$breaketpoint-mobile} {
    white-space: nowrap;
    overflow-x: scroll;
  }
  ul {
    display: inline-flex;
    li {
      display: inline-flex;
      flex-direction: column;
      border: 1px solid #ddd;
      align-items: center;
      padding: 6px 6px;
      width: 90px;
      line-height: 20px;
      cursor: pointer;
      @media #{$breaketpoint-mobile} {
        color: #fff;
        text-transform: uppercase;
        padding: 3px;
        width: 60px;
      }
      &.active {
        background-color: #ddd;
        color: $primaryColor;
        font-weight: 600;
        @media #{$breaketpoint-mobile} {
          background-color: #001a69;
          color: #fff;
        }
      }
    }
  }
}
.selectedMonthHeading {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid $white;
  padding: 12px;
  @media #{$breaketpoint-mobile} {
    font-size: 18px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;
  }
}
.fixture-info {
  display: flex;
  align-items: center;
  justify-content: center;
  .team-info {
    flex: 1 1 0%;
    min-width: 0;
  }
  .kickoff {
    background-color: transparent;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    border-radius: 3px;
    min-width: 54px;
    height: 28px;
    margin: 0 10px;
    span{
      font-size: 12px;
      padding-left: 3px;
    }
    @media #{$breaketpoint-mobile} {
      font-size: 14px;
      min-width:65px;
      height: 24px;
      margin: 0 5px;
    }
    &.score {
      background-color: $primaryColor;
      color: $white;
      font-weight: 600;
    }
  }
}
.league-rules {
  color: #333;
  line-height: 2.5em;
  .sectionHeading {
    margin: 0 -20px 20px;
    @media #{$breaketpoint-mobile} {
      margin: 0 -10px 10px;
    }
  }
}
.league-rules-content {
  background-color: $white;
  padding: 0px 20px 10px;
  @media #{$breaketpoint-mobile} {
    padding: 0px 10px 10px;
  }
}
.rules-label {
  color: $dark-blue;
  font-weight: 600;
  display: inline-block;
}
.rulesContent{
  line-height: 20px;
  span p{
    margin: 5px 0;
  }
}
.sectionSubHeading {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #425a7b;
  color: $white;
  padding: 0 10px;
  font-size: 15px;
  margin: 10px 0;
  @media #{$breaketpoint-mobile} {
    height: 30px;
  }
}
.player-pic {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #ddd;
  margin: 3px 10px;
  @media #{$breaketpoint-mobile} {
    height: 25px;
    width: 25px;
    margin: 3px 5px;
  }
}
.timeStamp {
  margin-top: -20px;
  padding: 0 20px 20px;
  text-align: right;
  @media #{$breaketpoint-mobile} {
    margin-top: -5px;
    padding: 0 15px 10px;
    font-size: 11px;
    color: #fff;
    background-color: #001a69;
  }
}
.timestamp-data{
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  background-color: #001a69;
  // padding-bottom: 5px;
  // min-height: 35px;
  .timeStamp {
    // margin-top: -20px;
    padding: 5px 10px 5px 5px;
    text-align: right;
    color: #fff;
  }
  .correction{
    font-size: 11px;
    padding-left: 10px;
    line-height: 18px;
  }
}
// Head to head Search
.headToHeadSearch {
  border-top: 1px solid $white;
  .contentBg {
    padding: 30px 100px;
    text-align: center;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../images/bg.jpg);
    @media #{$breaketpoint-mobile} {
      padding: 10px;
    }
  }
}
.headToHeadSearch-heading {
  text-transform: uppercase;
  color: $tertiaryColor;
  font-family: HelveticaCondensed;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 20px;
  @media #{$breaketpoint-mobile} {
    font-size: 15px;
    padding: 10px 0 15px;
  }
}
.headToHead-teamSearch {
  .headToHead-teamSearchGroup {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 40px;
    @media #{$breaketpoint-mobile} {
      margin-bottom: 20px;
    }
  }
  .form-label {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    @media #{$breaketpoint-mobile} {
      font-size: 13px;
    }
  }
  .form-control input {
    width: 350px;
    height: 40px;
    font-size: 16px;
    @media #{$breaketpoint-mobile} {
      width: 140px;
      height: 30px;
      font-size: 12px;
    }
  }
  .team-vs {
    height: 40px;
    line-height: 40px;
    margin: 0 20px;
    color: $tertiaryColor;
    font-size: 18px;
    font-weight: 700;
    @media #{$breaketpoint-mobile} {
      margin: 0 10px;
      font-size: 14px;
    }
  }
  .btn {
    width: 270px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    @media #{$breaketpoint-mobile} {
      width: 230px;
      height: 35px;
      font-size: 15px;
    }
  }
}
.headToHead-teamFilter {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media #{$breaketpoint-mobile} {
    flex-wrap: wrap;
  }
  .form-group {
    margin: 0 10px;
    @media #{$breaketpoint-mobile} {
      margin: 0 5px 10px;
    }
  }
  .form-label {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    @media #{$breaketpoint-mobile} {
      font-size: 13px;
    }
  }
  .form-control select {
    width: 350px;
    height: 40px;
    font-size: 16px;
    @media #{$breaketpoint-mobile} {
      width: 342px;
      height: 32px;
      font-size: 12px;
    }
  }
}
.orDivider {
  margin: 40px 0;
  font-size: 20px;
  font-weight: bold;
  color: $tertiaryColor;
  overflow: hidden;
  text-align: center;
  font-family: HelveticaCondensed;
  @media #{$breaketpoint-mobile} {
    margin: 20px 0;
    font-size: 15px;
    font-family: HelveticaCondensed;
  }
  &::after,
  &::before {
    background-color: $white;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    @media #{$breaketpoint-mobile} {
      width: 12%;
    }
  }
  &::before {
    left: 2.5em;
    margin-right: -50%;
  }
  &::after {
    right: 2.5em;
    margin-left: -50%;
  }
}
.headToHead-teamSearchSelection {
  margin-top: 90px;
  @media #{$breaketpoint-mobile} {
    margin-top: 30px;
    .active {
      background-color: $tertiaryColor !important;
    }
  }
  ul {
    margin-left: -20px;
    margin-right: -20px;
    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 58px;
      border-radius: 20px 0 20px 0;
      padding: 6px 8px;
      background-color: $white;
      font-size: 18px;
      margin: 0 10px 15px;
      position: relative;
      cursor: pointer;
      border: 1px solid $white;
      @media #{$breaketpoint-mobile} {
        width: 320px;
        height: 25px;
        font-size: 14px;
        justify-content: space-between;
        border-radius: 10px 0 10px 0;
        padding: 5px 10px;
        margin: 0 10px 10px;
        .active {
          background-color: $tertiaryColor !important;
        }
      }

      &:hover {
        background-color: $dark-blue;
        font-weight: 600;
        color: $white;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          content: "+";
          width: 20px;
          height: 20px;
          background-color: $white;
          color: $dark-blue;
        }
      }
      img {
        height: 30px;
        padding: 0 6px;
      }
    }
  }
}
.player-icon {
  display: flex;
  flex-direction: row;
}
.neutral {
  background-color: #ff6f29;
}
.groupStagelogo{
  margin-right: 5px;
  img{
    width: 20px;
  }
}
.groupTable-heading{
  width: 100%;
  margin-top: 10px;
}