.headerDetails {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 15px;
  font-weight: 600;
  //   width: 100%;
  padding: 5px 10px;
  @media #{$breaketpoint-mobile} {
    font-size: 12px;
    padding: 5px;
  }
}

.head-playerRank {
  width: 60px;
  @media #{$breaketpoint-mobile} {
    width: 50px;
  }
}
.head-playerInfo {
  flex: 1 1 auto;
}
.head-goalsNumber,
.playerGoals {
  width: 60px;
  text-align: center;
}
.head-playerHkjcno,
.playerHkjcno {
  width: 40px;
  text-align: right;
  @media #{$breaketpoint-mobile} {
    width: 60px;
  }
}
.topScore-playerdetails {
  width: 100%;
  display: flex;
  background-color: #fff;
  align-items: center;
  overflow: hidden;
  height: 70px;
  position: relative;
  border-bottom: solid 1px #ddd;
  .playerRank {
    min-width: 52px;
    max-width: 52px;
    position: relative;
    left: -10px;
    transform: skew(-15deg);
    padding: 3px 5px;
    background-color: #09b3c6;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 26px;
    color: #fff;
    @media #{$breaketpoint-mobile} {
      font-size: 16px;
      min-width: 35px;
      max-width: 35px;
    }
  }
  .playerIcon {
    background-color: #fff;
    margin-right: 10px;
    img {
      min-width: 60px;
      max-width: 60px;
    }
    @media #{$breaketpoint-mobile} {
      margin-right: 3px;
    }
  }
  .playerCountry {
    background-color: #fff;
    width: 40px;
    padding-right: 10px;
    //position: absolute;
    //left: 350px;
    img {
      min-width: 20px;
      max-width: 20px;
    }
    @media #{$breaketpoint-mobile} {
      width: 20px;
      padding-right: 5px;
    }
  }
  .playerInfo {
    color: #000;
    display: flex;
    background-color: #fff;
    width: 350px;
    //padding: 0 10px 0 120px;
    flex: 1 1 auto;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    @media #{$breaketpoint-mobile} {
      font-size: 12px;
      width: 160px;
      flex: 1 1 auto;
    }
    .text-blue {
      color: #001a69;
      text-transform: uppercase;
      @media #{$breaketpoint-mobile} {
        font-size: 11px;
      }
    }
  }
  .playerGoals {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding-left: 6px;
    .goalsNumber {
      border-radius: 50%;
      background-color: #ff6f29;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }
    .crossline {
      width: 3px;
      border-left: 2px dotted #ccc;
      transform: skew(-15deg);
      margin: 0 10px;
    }
  }
  .playerHkjcno {
    background-color: #fff;
    font-weight: 600;
    padding-right: 10px;
    font-size: 20px;
    text-align: center;
    color: #001a69;
    @media #{$breaketpoint-mobile} {
      font-size: 15px;
      // width: 120px;
    }
  }
}

// .topScorer-container {
//   padding: 0 20px 20px;
//   color: #fff;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   .playerList {
//       display: flex;
//       flex-direction: column;
//       width: 250px;
//       margin: 0 5px;
//       margin-bottom: 20px;
//   }
//   .playerPhoto {
//       position: relative;
//       .goals {
//           position: absolute;
//           bottom: 10px;
//           right: 10px;
//           text-align: center;
//           .goalsHeading {
//               color: #ff6f29;
//               font-size: 16px;
//               font-weight: 600;
//           }
//           .goalsNumber {
//               border-radius: 50%;
//               background-color: $tertiaryColor;
//               border: solid 1px #ccc;
//               width: 50px;
//               height: 50px;
//               display: flex;
//               justify-content: center;
//               align-items: center;
//               font-size: 25px;
//               font-weight: 600;
//           }
//       }
//       img {
//           width: 250px;
//       }
//   }
//   .playerName {
//       background-color: $tertiaryColor;
//       height: 40px;
//       display: flex;
//       align-items: center;
//       padding: 5px 10px;
//       font-size: 25px;
//       font-weight: 600;
//   }
//   .playerDetails {
//       background-color: #fff;
//       padding: 10px;
//       color: #001a69;
//       line-height: 35px;
//       font-weight: 600;
//       text-transform: uppercase;
//       font-size: 17px;
//       .teamName img {
//           margin-right: 10px;
//       }
//       .teamPosition {
//           font-size: 20px;
//           margin-top: 20px;
//       }
//   }
// }
