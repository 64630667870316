.winPredictor-bestBet {
    display: flex;
    justify-content: space-between;
    margin: 0 20px 10px 20px;
    @media #{$breaketpoint-mobile}{
        flex-direction: column;
        margin: 0 5px 5px 5px;
		}

    .winPredictor {
        display: flex;
        background-color: #224bb2;
        background-image: url(../../images/winPredictor-bg.jpg);
        background-size: cover;
        padding: 10px;
        width: 48%;
        border-radius: 12px;
        min-height: 150px;
        // justify-content: space-evenly;
        @media #{$breaketpoint-mobile}{
            width: auto;
            min-height: 100px;
            margin-bottom: 5px;
            }
        .winPredictor-iconGroup {
            text-align: center;
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
            width: 218px;
            @media #{$breaketpoint-mobile}{
                width: 130px;
                }
            .winPredictor-icon{
                height: 80px;
                @media #{$breaketpoint-mobile}{
                    height: 60px;
                    }
            }
            img {
                height: 80px;
                @media #{$breaketpoint-mobile}{
                    height: 60px;
                    }
            }
            .win {
                display: flex;
                font-size: 50px;
                color: #fff;
                font-weight: 600;
                height: 45px;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-family: HelveticaCondensed;
                @media #{$breaketpoint-mobile}{
                    font-size: 25px;
                    height: 25px;
                    text-transform: uppercase;
                    font-family: HelveticaCondensed;
                    }
            }
            .win-chinese {
                display: flex;
                font-size: 35px;
                color: #fff;
                font-weight: 600;
                height: 45px;
                justify-content: center;
                align-items: center;
                font-family: HelveticaCondensed;
                text-transform: uppercase;
                @media #{$breaketpoint-mobile}{
                    font-size: 18px;
                    height: 18px;
                    text-transform: uppercase;
                    font-family: HelveticaCondensed;
                    }
              }
            .predictor {
                font-size: 18px;
                color: #fff;
                font-weight: 600;
                text-align: center;
                font-family: HelveticaCondensed;
                @media #{$breaketpoint-mobile}{
                    font-size: 13px;
                    text-transform: uppercase;
                    font-family: HelveticaCondensed;
                    }
            }
        }
        .winPredictor-content {
            // display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .bestBet {
        display: flex;
        // justify-content: space-evenly;
        background-color: #fff;
        padding: 10px;
        width: 48%;
        border-radius: 12px;
        min-height: 150px;
        @media #{$breaketpoint-mobile}{
            width: auto;
            min-height: 100px;
            border: solid 1px #ddd;
            box-shadow: 0px 0px 2px #ddd;
            }
        .bestBet-strip {
            position: relative;
            right: 43px;
        }
        .bestBet-graph {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 218px;
            justify-content: flex-end;
            // margin: 20px 10px 0 0;
            .bestBet-piechart{
                position: relative;
                top: -7px;
                left: 15px;
            }
            @media #{$breaketpoint-mobile}{
                margin-top: 0px;
                height: 122px;
                width: 160px;
                max-width: 160px;
            }
            .bestBet-verticaltext {
                font-weight: 600;
                position: relative;
                text-align: center;
                left: 0px;
                height: 30px;
                top: 37px;
                font-size: 14px;
                line-height: 15px;
                color: #652cb7;
                // transform: rotate(-90deg);
                // -webkit-transform: rotate(-90deg);
                // -moz-transform: rotate(-90deg);
                // -ms-transform: rotate(-90deg);
                // -o-transform: rotate(-90deg);
                filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                @media #{$breaketpoint-mobile}{
                    top: 0px;
                    left: 10px;
                    font-size: 13px;
                }
            }
            .bestBet-piechart {
                position: relative;
                top: 0px;
              }
            .bestBet-horizontaltext {
                font-size: 14px;
                color: #652cb7;
                font-weight: 600;
                text-align: center;
                top: -14px;
                position: relative;
                @media #{$breaketpoint-mobile}{
                    font-size: 13px;
                    top: 0px;
                    left: 10px;
                }
            }
            img {
                height: 140px;
                @media #{$breaketpoint-mobile}{
                    height: auto;
                }
            }
        }
        .bestBet-content {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: space-around;
            width: 100%;
            .bestBet-fixture {
                font-size: 16px;
                align-items: flex-start;
                font-weight: bold;
                color: #224bb2;
                @media #{$breaketpoint-mobile}{
                    font-size: 14px;
                }
            }
            .rtb {
                display: flex;
                padding: 10px;
                line-height: 25px;
                font-size: 15px;
                @media #{$breaketpoint-mobile}{
                    padding: 5px;
                    line-height: 15px;
                    font-size: 11px;
                    }
            }
        }
    }
    
.ribbon {
    height: 30px;
    position: relative;
    font-size: 12px;
    color: white;
    @media #{$breaketpoint-mobile}{
        top:-5px
    }
  }
  .ribbon3 {
    width: 60px;
    height: 25px;
    line-height: 27px;
    padding-left: 10px;
    position: absolute;
    left: -15px;
    top: 2px;
    background: #E0213E;
    @media #{$breaketpoint-mobile}{
        padding-left: 8px;
        width: 52px;
        height: 20px;
        line-height: 20px;
        }
  }
  .ribbon3:before, .ribbon3:after {
    content: "";
    position: absolute;
  }
  .ribbon3:before {
    height: 0;
    width: 0;
    top: -7px;
    left: 0px;
    border-bottom: 7px solid #B3465E;
    border-left: 7px solid transparent;
    @media #{$breaketpoint-mobile}{
    top: -4px;
    left: 0px;
    border-bottom: 4px solid #B3465E;
    }
  }
  .ribbon3:after {
    height: 0;
    width: 0;
    right: -3.5px;
    border-top: 13px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 4px solid #E0213E;
    @media #{$breaketpoint-mobile}{
        border-bottom: 6px solid transparent;
        }
  }
}