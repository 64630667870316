  .contentBg {
    // display: flex;
    background-color: $secondaryColor;
    padding: 30px 15px;
    background: url(../../images/bg.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    @media #{$breaketpoint-mobile}{ 
      background-image: none;
      padding: 0px;
    }
  }
  .mainSearchbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0 0 0;
    // margin: 2px 0px;
    background-color: #f5f5f5;
    // align-items: center;
  }
  .mainSearchbar-leftSection {
    ul {
      overflow: hidden;
      li {
        display: inline-block;
        a {
          display: block;
          text-align: center;
          color: #001a69 !important;
          padding: 6px 15px;
          @media #{$breaketpoint-mobile}{
            padding: 2px 5px;
            }
        }
        a:hover {
          background-color: #001a69;
          color: $white !important;
        }
        .icons {
          font-size: 25px;
          @media #{$breaketpoint-mobile}{
            font-size: 20px;
            }
        }
        &.active{
          border-bottom: solid 3px #001a69;
        }
      }
    }
  }
  .mainSearchbar-rightSection {
    display: flex;
    align-items: center;
    margin-right: 15px;
    @media #{$breaketpoint-mobile}{
      margin-right: 5px;
      select{
      height: 27px;
      position: relative;
      top: -3px;
      border: solid 1px #ccc;
      border-radius: 3px;
      background: #fff;
      left: 0;
    }
      }
  }
  .strengthMeter {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
    display: inline-block;
    line-height: 25px;
    color: $white;
    &.green {
      background-color: $color1;
    }
    &.orange {
      background-color: $color2;
    }
    &.maroon {
      background-color: $color3;
    }
    &.purple {
      background-color: $color4;
    }
    &.lime {
      background-color: $color5;
    }
    &.pink {
      background-color: $color6;
    }
    &.lightgreen {
      background-color: $color7;
    }
    &.lightblue {
      background-color: $color8;
    }
    &.lightbrown {
      background-color: $color9;
    }
  }
  .team-vs{
    color: #F78A0A;
    font-weight: 600;
    padding: 0 5px;
  }