.layout-header {
	background-color: $primaryColor;

	@media #{$breaketpoint-mobile} {
		background-color: #001a69;
	}

	// .mainHeader {

	// }
	.mainHeader-content {
		height: 90px;
		// margin: 0 auto;
		display: flex;
		justify-content: space-between;
		background-color: #fff;

		@media #{$breaketpoint-mobile} {
			height: auto;
			// background-color: #fff;
		}
	}

	.mainHeader-left {
		display: flex;

		@media #{$breaketpoint-mobile} {
			width: 100%;
		}
	}

	.mainHeader-right {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media #{$breaketpoint-mobile} {
			width: 80%;
			text-align: right;
			margin: 0px 10px;
		}
	}

	.lan {
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		svg {
			width: 20px;
			margin-top: 3px;
		}

	}

	.mainHeader-logo {
		display: flex;
		padding: 0 10px;
		align-items: center;

		@media #{$breaketpoint-mobile} {
			padding: 5px;
		}

		img {
			width: 110px;

			@media #{$breaketpoint-mobile} {
				width: 125px;
			}
		}
	}

	// main header navigation
	.mainHeader-tabs {
		display: flex;
		align-items: flex-end;

		@media #{$breaketpoint-mobile} {

			width: 100%;
		}

		ul {
			display: flex;
			margin-left: 10px;

			@media #{$breaketpoint-mobile} {
				margin-left: 0px;
				width: 100%;
			}

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 45px;
				min-width: 100px;
				color: $white;
				border-radius: 4px 4px 0 0;
				padding: 0 18px;
				margin: 0 5px;
				font-family: HelveticaCondensed;
				font-size: 18px;
				justify-content: flex-start;
				text-transform: uppercase;

				@media #{$breaketpoint-mobile} {
					background-color: #17396b;
					margin: 0;
					border-radius: 0;
					font-size: 14px;
					height: 40px;
					padding: 0 0px;
					width: 100%;
					line-height: 40px;
				}

				a {
					display: flex;
					color: inherit;
					align-items: center;
					justify-content: center;
					min-width: 100px;
					height: 40px;
					//widt added for 2 tabs
					width: 100%;

					padding: 0 10px;
					margin: 0 0px;

					&:hover {
						background-color: #ff6f29;
						color: $white;
					}

					&.active {
						// background-color: $background-white;
						background-color: $white;
						color: $black;
						font-weight: bold;

						@media #{$breaketpoint-mobile} {
							background-color: #ff6f29;
							font-size: 15px;
							color: $white !important;
						}

						&:hover {
							background-color: #ff6f29;
							color: #000;
							font-weight: bold;
						}
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

// Sub navigation
.subHeader {
	height: 50px;
	display: flex;
	align-items: center;
	background-color: #fff;
	justify-content: space-between;
	font-family: HelveticaCondensed;
	font-weight: bold;

	@media #{$breaketpoint-mobile} {
		height: 31px;
		background-color: #fff;
		// menu scroll
		white-space: nowrap;
		overflow-x: scroll;
		text-transform: uppercase;
		border-top: solid 0px #fff;
		border-bottom: solid 1px #fff;
	}

	ul {
		li {
			display: inline-block;
			color: #001a69;
			text-align: center;
			padding: 2px 16px;
			text-decoration: none;
			font-size: 16px;
			border-radius: 4px;
			margin: 0 10px;
			height: 34px;
			line-height: 36px;

			@media #{$breaketpoint-mobile} {
				padding: 2px 6px;
				margin: 0 4px;
				height: 24px;
				line-height: 28px;
				font-size: 13px;
				border-radius: 0px;
			}

			a {
				color: inherit;
				padding: 10px 20px;
				cursor: pointer;
				letter-spacing: 1px;

				@media #{$breaketpoint-mobile} {
					padding: 1px;
					letter-spacing: 0px;
				}
			}

			&:hover {
				background-color: #d7dce9;
				color: black;
			}

			&.active {
				// background-color: $tertiaryColor;
				color: #fff;

				@media #{$breaketpoint-mobile} {
					color: #fff !important;
					background-color: $tertiaryColor;
					border-bottom: solid 2px $tertiaryColor;
					margin-left: 5px;
					border-radius: 3px;
				}
			}
		}
	}

	.changeMode {
		li {
			// border: 1px solid #001a69;
			color: #fff !important;
			font-weight: 700;
			font-size: 18px !important;
			background-color: #001a69;
			border-radius: 35px;

			@media #{$breaketpoint-mobile} {
				height: 20px;
				line-height: 22px;
				border-radius: 4px;
			}
		}
	}

	.changeMode:hover {
		li:hover {
			color: #fff !important;
			font-weight: 700;
			background-color: #001a69;
			border-radius: 35px;
		}
	}

}

.mainTeamBanner {
	.gameDetails {
		display: flex;
		flex-direction: row;
		border-top: solid 1px $white;
		// border-bottom: solid 1px $white;
		background-color: $white;
		position: relative;
		height: 110px;

		@media #{$breaketpoint-mobile} {
			height: auto;
		}

		.homeTeam,
		.awayTeam {
			display: flex;
			width: 50%;
			padding: 5px 0;

			@media #{$breaketpoint-mobile} {
				flex-direction: column;
			}
		}

		.homeTeam {
			@media #{$breaketpoint-mobile} {
				align-items: flex-start;
				padding-left: 7px;
				// align-items: center;
			}
		}

		.awayTeam {
			@media #{$breaketpoint-mobile} {
				align-items: flex-end;
				padding-right: 7px;
				// align-items: center;
			}
		}

		.homeTeam {
			background-color: #0c5483;
		}

		.homeTeam-logo {
			display: flex;
			align-items: center;
			padding: 0px 20px 0px 10px;

			@media #{$breaketpoint-mobile} {
				justify-content: flex-start;
				color: #fff;
				align-items: flex-start;
				padding: 0px 0px 0px 0px;
				width: 60%;
			}

			img {
				width: auto;
				height: 60px;
			}

			.teamPosition {
				@media #{$breaketpoint-mobile} {
					font-size: 12px;
					flex-direction: column;
					display: flex;
				}

				.positionNumber {

					// text-align: center;
					@media #{$breaketpoint-mobile} {
						text-align: center;
					}
				}
			}
		}

		.awayTeam-logo {
			display: flex;
			align-items: center;
			padding: 0px 20px 0px 10px;

			@media #{$breaketpoint-mobile} {
				justify-content: flex-end;
				color: #fff;
				align-items: flex-start;
				padding: 0px 0px 0px 0px;
				width: 60%;
			}

			img {
				width: auto;
				height: 60px;
			}

			.teamPosition {
				@media #{$breaketpoint-mobile} {
					font-size: 12px;
					flex-direction: column;
					display: flex;
				}

				.positionNumber {

					// text-align: center;
					@media #{$breaketpoint-mobile} {
						text-align: center;
					}
				}
			}
		}

		// .homeTeam-logo{
		// 	@media #{$breaketpoint-mobile}{
		// 		width: 112px;
		// 		}
		// }
		// .awayTeam-logo{
		// 	@media #{$breaketpoint-mobile}{
		// 		width: 112px;
		// 		}
		// }
		.homeTeam-details,
		.awayTeam-details {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.teamName {
				display: flex;
				align-items: center;
				font-size: 50px;
				font-weight: 600;
				color: $white;
				text-transform: uppercase;
				height: 60px;
				font-family: HelveticaCondensed;


				@media #{$breaketpoint-mobile} {
					font-size: 14px;
					min-height: 30px;
					height: auto;
					// width: 102px;
					width: auto;
					font-family: HelveticaCondensed;
				}
			}

			@media screen and (max-width: 450px) {
				.teamName {
					width: 105px;
					display: flex;
					justify-content: flex-start;
					gap: 5px;
					align-items: flex-end;
					overflow-wrap: anywhere;
				}

				.a_teamname {
					justify-content: flex-end !important;
				}

				a {
					color: #fff;
					font-size: 16px;
				}
			}

			.teamPosition {
				display: flex;
				flex-direction: row;
				font-size: 25px;
				color: $white;
				padding-top: 5px;

				@media #{$breaketpoint-mobile} {
					font-size: 15px;
				}
			}

			// .teamPosition-new{

			// 	@media #{$breaketpoint-mobile} {
			// 		display: flex;
			// 	flex-direction: column;
			// 	}
			// }
		}

		.awayTeam {
			justify-content: flex-end;
			background-color: #670633;
		}
	}
}

.leagueDetails {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 250px;
	padding: 4px 0;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	justify-content: space-between;
	min-height: 94%;

	@media #{$breaketpoint-mobile} {
		width: 142px;
	}

	.kickOff-details {
		background-color: $white;
		// -webkit-transform: skew(-15deg);
		// -moz-transform: skew(-15deg);
		// transform: skew(-15deg);
		padding: 3px 5px;
		font-size: 13px;
		font-weight: 600;
		min-width: 200px;

		@media #{$breaketpoint-mobile} {
			padding: 3px;
			font-size: 10px;
			// min-width: 123px;
			min-width: 130px;
			margin-top: 3px;
			line-height: normal;
			// -webkit-transform: skew(-10deg);
			// -moz-transform: skew(-10deg);
			// transform: skew(-10deg);
		}

		.normalPosition {
			// -webkit-transform: skew(15deg);
			// -moz-transform: skew(15deg);
			// transform: skew(15deg);
			// @media #{$breaketpoint-mobile} {
			// 	-webkit-transform: skew(10deg);
			// -moz-transform: skew(10deg);
			// transform: skew(10deg);
			// }
			display: flex;
			gap: 5px;
			flex-direction: column;
		}

		.a_firstPostion {
			display: flex;
			text-wrap: nowrap;
			gap: 5px;
			align-items: center;
			justify-content: center;
		}

		.date {
			font-size: 12px;

			@media #{$breaketpoint-mobile} {
				font-size: 12px;
			}
		}

		.time {
			padding: 0 10px;
		}
	}

	.oddsDetails {
		display: flex;
		margin-top: 3px;
		margin-bottom: 3px;

		.odds {
			background-color: #09b3c6;
			padding: 5px 0;
			text-align: center;
			width: 55px;

			@media #{$breaketpoint-mobile} {
				padding: 3px;
				width: 32px;
				font-size: 13px;
			}

			&.home {
				background-color: $homeTeamColor;
				color: $white;
				padding: 7px;
			}

			&.draw {
				background-color: $drawTeamColor;
				color: $black;
				padding: 7px;
			}

			&.away {
				background-color: $awayTeamColor;
				color: $white;
				padding: 7px;
			}
		}
	}
}

.hamburger {
	padding-right: 8px;
}